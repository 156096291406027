import React from 'react'
import { useLocation } from 'react-router-dom';

export default function Footer() {
    
    // retrieve pathname to edit "li" color
    const pathname = useLocation().pathname
    if (pathname.includes('/admin') || pathname.includes('/login')) return null; 

    return (
        <footer>
            
        </footer>
    )
}
