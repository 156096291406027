import React, { FormEvent, useEffect, useState } from 'react'
import { BACK_URL } from '../../../config/backendURL';
import Members from '../../../config/members.request';
import { IMember } from '../../../config/types';
import MyLoader from '../../others/loader';

export default function ManageMembers() {

    const [ members, setMembers ] = useState<IMember[] | null>([]);
    const [ loading, setLoading ] = useState(true);

    // new member fields
    const [ name, setName ] = useState('');
    const [ desc, setDesc ] = useState('');
    const [ errorMessage, setErrorMessage ] = useState('');
    const [ errorStatus, setErrorStatus ] = useState<boolean>()
    const [ picFile, setPicFile ] = useState<Blob>();
    const [ picPreview, setPicPreview ] = useState('');

    // get members list
    useEffect(() => {
        getAll();
    },[])

    // get member
    const getAll = async () => {
        const membersList = await Members.getAll()
        
        setLoading(false);
        setMembers(membersList);
    }


    // add member
    async function newMember(e: FormEvent<HTMLFormElement> ){
        e.preventDefault();

        if (!(name.length > 0 ) || !(desc.length > 0 ) || !picFile) {
            setErrorStatus(true);
            return setErrorMessage('Vous devez remplir tous les champs');
        }

        try {
            const req = await Members.newMember(name, desc, picFile);

            if (!req) {
                return setErrorMessage("Une erreur s'est produite lors de la création du membre, veuillez réessayer.")
            }

            setErrorMessage('Le membre a bien été ajouté !');
            setErrorStatus(false)
            resetState()
            return getAll()
        }catch(err) {
            return setErrorMessage("Une erreur s'est produite lors de la création du membre, veuillez réessayer.")
        }
    } 

    function resetState() { 
        setName('');
        setDesc('');
        setPicFile(undefined);
        setPicPreview('');
    }

    // handle file - new member
    function handleFiles(files: FileList | null) {
        if (!files) return;
        const image = files[0]
        
        setPicFile(image)
        setPicPreview(URL.createObjectURL(image))
    }


    return (
        <div className="manage-members-container"> 
            <h2>GESTION DES MEMBRES</h2>

            <h3>Ajouter des membres: </h3>
            <div className="form-container">
                <form onSubmit={newMember}>
                    <input value={name} className="shadow-box" placeholder="prénom nom" type="text" name="username" id="username" onChange={(e) => setName(e.target.value)} autoComplete="off" />
                    <textarea value={desc} className="shadow-box" placeholder="description" name="description" id="password" onChange={(e) => setDesc(e.target.value)} autoComplete="off" />
                    <div className="element-input-container">
                        <input type="file" name="file" id="picture-file" className="inputfile" onChange={(e) => handleFiles(e.target.files)}  />
                        {/* @ts-ignore */}
                        <label htmlFor="picture-file" className="shadow-box">{ picFile?.name ? picFile.name : "Choisissez une photo de profil"} {picPreview.length > 0 && <img src={picPreview} alt={picFile?.name}/>} </label>
                    </div>
                    { errorMessage.length > 0 && <p className={`info-message ${'error-'+errorStatus}`}>{errorMessage}</p>}
                    <div className="button-container">
                        <button type="submit" className="button-primary shadow-box">
                            AJOUTER
                        </button>
                    </div>
                </form>
            </div>

            <div className="members-list-container">
                {
                    loading ? <MyLoader />:

                    // @ts-ignore
                    members?.length > 0 ? 
                        members?.map((m: IMember,i ) => {
                            return <MemberComponent key={i} member={m} getMembers={getAll} />
                        })
                        :
                        <p>Il n'y a aucun membre ajouté.</p>
                }
            </div>

        </div>
    )
}


function MemberComponent({ member, getMembers }: { member: IMember, getMembers: () => void }) {
    const picUrl = `${BACK_URL}/files/members/picture/${member.uuid}`;

    // del member
    async function delMember() {
        try {
            const confirm = window.confirm("Êtes-vous sûr de vouloir supprimer ce membre ?");
            if (!confirm) return;
            const del = await Members.delMember(member.uuid);
            
            if (del) {
                return await getMembers();
            }
        }
        catch(err) {
            // error
            console.log(err)
        }
    }

    return (
        <div onClick={delMember}>
            <div className="img-container" >
                <img src={picUrl} alt={member.name} />
            </div>
            <h3>{member.name}</h3>
            <p>{member.desc}</p>
        </div>
    )
}