import React, { useEffect, useMemo } from 'react'
import { Redirect, useLocation } from 'react-router-dom'
import Content from '../../components/admin/content'
import ManageUser from '../../components/admin/manageusers'
import ManageMembers from '../../components/admin/members'
import Sidebar from '../../components/admin/sidebar'
import { IUser } from '../../config/types'
import ManageArticle from './articles'
import ManagePodcast from './podcasts'

export default function Admin({ user }: { user: IUser }) {

    useEffect(() => { document.title = "New Wave | ADMIN" }, [])

    const location = useLocation()

    const actualPage = useMemo(() => {
        const tab = new URLSearchParams(location.search).get('tab')
        const mode = new URLSearchParams(location.search).get('mode')

        switch (tab) {
            case "users":
                if (user.role_rank !== 1) return;
                return <ManageUser />
            case "articles" :
                if (mode) return <ManageArticle mode={mode} />
                return <Content type="articles" />
            case "podcasts" :
                if (mode) return <ManagePodcast mode={mode} />
                return <Content type="podcasts" />
            case "members": 
                if (user.role_rank !== 1) return;
                return <ManageMembers />
            default : 
                return <Redirect to="/admin/?tab=podcasts" /> 
            
            
        }
    },[ location, user.role_rank ])

    return (
        <main className="admin-container">
            <Sidebar user={user} />
            <section
                className="info-component"
            >
                { actualPage }
            </section>
        </main>
    )
}
