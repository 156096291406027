import React, { useEffect, useState } from 'react'
import Subtitle from '../../components/others/subtitle'
import ListElement from '../../components/others/listelement'
import { IPosts } from '../../config/types'
import Post from '../../config/post.request'
import MyLoader from '../../components/others/loader'

export default function Podcasts() {

    const [ data, setData ] = useState<IPosts[]>([])
    const [ loading, setLoading ] = useState(true);

    useEffect(() => {
        document.title = "New Wave | PODCASTS"
        const getPosts = async () => {
            const postsData = await Post.getAllPosts("podcasts");

            setLoading(false);
            if (!postsData) return;
            return setData(postsData)
        }
        getPosts();
    },[])

    return (
        <main className="podcast-container">
            <Subtitle subtitle="NOS PODCASTS" />
            <section>
                {
                    loading ? <MyLoader />:
                    data.map(e => {
                        return <ListElement key={e.uuid} data={e} type={e.post_type} />
                    })
                }
            </section>
        </main>
    )
}
