import React, { useEffect, useState } from 'react'
import { FaEdit } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import Post from '../../../config/post.request'
import { IPosts } from '../../../config/types'
import ListElement from '../../others/listelement'
import MyLoader from '../../others/loader'

export default function Content({ type } : { type: string; }) {

    const [ posts, setPosts ] = useState<IPosts[]>([])
    const [ loading, setLoading ] = useState(true);

    useEffect(() => {
        setLoading(true);
        const getPosts = async () => {
            const postsData = await Post.getAllPosts(type);
            
            if (!postsData) return;
            setLoading(false);
            return setPosts(postsData)
        }
        getPosts();
    },[ type ])

    return (
        <div className="content-admin-container">
            <h2>{type.toUpperCase()}</h2>
            <Link to={`/admin/?tab=${type}&mode=add`} className="button-primary shadow-box">
                AJOUTER
            </Link>
            {
                loading ? <MyLoader /> :
                posts.map(p => {
                    return (
                        <div key={p.uuid} className="last-component-container">
                            <ListElement data={p} type={type} />
                            <div className="button-container">
                                <Link to={`/admin/?tab=${type}&mode=edit&uid=${p.uuid}`} className="button-primary shadow-box">
                                    <p>Modifier <FaEdit size={20} /></p>
                                </Link>
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}
