import React from 'react'
import { BACK_URL } from '../../config/backendURL';
import { IMember } from '../../config/types'

export default function MemberList({ member, i } : { member : IMember, i: number }) {
    const picUrl = `${BACK_URL}/files/members/picture/${member.uuid}`;

    const picSide = i%2 === 0 ? "left" : "right"

    return (
        <div className={`member-box ${picSide}`}>
            <div className="img-container">
                <img src={picUrl} alt={member.name}/>
            </div>
            <div className="text-container">
                <h2>{member.name}</h2>
                <p>{member.desc}</p>
            </div>
        </div>
    )
}
