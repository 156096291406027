import React, { useEffect, useState } from 'react'
import { FaBars, FaTimes } from 'react-icons/fa';
import { Link, useLocation } from 'react-router-dom'

// topbar
export default function Topbar() {

    const [ open, setOpen ] = useState(false);

    // retrieve pathname to edit "li" color
    const pathname = useLocation().pathname

    useEffect(() => {
        setOpen(false);
    },[ pathname ])

    if (pathname.includes('/admin') || pathname.includes('/login')) return null; 
    
    return (
        <header>
            <div className="topbar-container">
                <Link to="/" className="logo-container">
                    <img src="/logo.png" alt="logo.png"/>
                </Link>
                <ul>
                    <Link
                        to="/"
                        className={["/",""].includes(pathname) ? "select" : "unselect"}
                        >ACCUEIL</Link>
                    <Link
                        to="/podcasts"
                        className={pathname.includes("podcast") ? "select" : "unselect"}
                        >PODCASTS</Link>
                    <Link
                        to="/articles"
                        className={pathname.includes("articles") ? "select" : "unselect"}
                        >ARTICLES</Link>
                    <Link
                        to="/commu"
                        className={pathname.includes("commu") ? "select" : "unselect"}
                        >COMMUNAUTÉ</Link>
                    <Link
                        to="/contacts"
                        className={pathname.includes("contacts") ? "select" : "unselect"}
                        >NOUS CONTACTER</Link>
                </ul>
                <div className="menu">
                    <FaBars onClick={() => setOpen(true)} />
                    {
                        open && (
                            <div className="menu-container">
                                <div className="menu-exit" onClick={() => setOpen(false)}>
                                    <FaTimes color="white" />
                                </div>
                                <div className="link-container">
                                    <Link
                                        to="/"
                                        className={["/",""].includes(pathname) ? "select" : "unselect"}
                                        >ACCUEIL</Link>
                                    <Link
                                        to="/podcasts"
                                        className={pathname.includes("podcast") ? "select" : "unselect"}
                                        >PODCASTS</Link>
                                    <Link
                                        to="/articles"
                                        className={pathname.includes("articles") ? "select" : "unselect"}
                                        >ARTICLES</Link>
                                    <Link
                                        to="/commu"
                                        className={pathname.includes("commu") ? "select" : "unselect"}
                                        >COMMUNAUTÉ</Link>
                                    <Link
                                        to="/contacts"
                                        className={pathname.includes("contacts") ? "select" : "unselect"}
                                        >NOUS CONTACTER</Link>
                                </div>
                            </div>
                        )
                    }
                </div>
                
            </div>
        </header>
    )
}
