import React from 'react'
import { Link } from 'react-router-dom'
import { BACK_URL } from '../../config/backendURL'
import { IPosts } from '../../config/types'

export default function ListElement({ type, data } : { type: string; data: IPosts}) {
    
    return (
        <Link to={`/${type}/${data.uuid}`} className="component-list-container">
            <div className="img-container">
                <img src={`${BACK_URL}/files/${data.uuid}/${data.picture_path}`} alt={data.picture_path} className="shadow-box" />
            </div>
            <div className="info-container">
                <p className="date">{manageDate(data.date)}</p>
                <h3>{data.title.toUpperCase()}</h3>
                <p className="content">{data.desc}</p>
                <div className="flag-container">
                    {
                        data.flag && data.flag.split(',').map(e => e.trim()).map((e,i) => {
                            return (
                                <div className="flag-box shadow-box" key={i}>
                                    <p>{e.toUpperCase()}</p>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </Link>
    )
}

function manageDate(date: string) {
    const splitDate = date.split('-')
    return `${splitDate[2]}/${splitDate[1]}/${splitDate[0]}`
}