import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Subtitle from '../../components/others/subtitle';
import ListElement from '../../components/others/listelement';
import Post from '../../config/post.request';
import { IPosts } from '../../config/types';
import MyLoader from '../../components/others/loader';

export default function Home() {

    const [ podcast, setPodcast ] = useState<IPosts | {}>({})
    const [ article, setArticle ] = useState<IPosts | {}>({})
    const [ loading, setLoading ] = useState(true);

    useEffect(() => {
        document.title = "New Wave" 

        const getLasts = async() => {
            const podcast: IPosts | null = await Post.getLastPost("podcasts");
            const article: IPosts | null = await Post.getLastPost("articles");
            
            podcast && setPodcast(podcast);
            article && setArticle(article);
            return setLoading(false);
        }
        getLasts();
    },[])

    return (
        <main className="home-container">
            <div className="main-info-container shadow-box"></div>
            <section>
                {
                    loading ? <MyLoader /> : 
                    (
                        <>
                        {
                            Object.keys(podcast).length > 0 && (
                                <>
                                <Subtitle subtitle="NOTRE DERNIER PODCAST" />
                                <div className="last-component-container">
                                    {/* @ts-ignore */}
                                    <ListElement data={podcast} type="podcasts" />
                                    <div className="button-container">
                                        <Link to="/podcasts" className="button-primary shadow-box">
                                            <p>Voir nos autres podcasts</p>
                                        </Link>
                                    </div>
                                </div>
                                </>
                            )
                        }
                        {
                            Object.keys(article).length > 0 && (
                                <>
                                <Subtitle subtitle="NOTRE DERNIER ARTICLE" />
                                <div className="last-component-container">
                                    {/* @ts-ignore */}
                                    <ListElement data={article} type="articles" />
                                    <div className="button-container">
                                        <Link to="/articles" className="button-primary shadow-box">
                                            <p>Voir nos autres articles</p>
                                        </Link>
                                    </div>
                                </div>
                                </>
                            )
                        }
                        </>
                    )
                    }
            </section>
        </main>
    )
}
