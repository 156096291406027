import { API } from "./api";
import { IMember } from "./types";

class MembersReq {

    // get members
    async getAll() : Promise<IMember[] | null> {
        try {
            const res = await API.get('/members')

            return res.data;
        }
        catch(err) {
            return null;
        }
    }

    // new members
    async newMember(name: string, desc: string, picFile: Blob): Promise<boolean> {
        try {

            const formData = new FormData();
            formData.append("picture", picFile);
            formData.append("name", name);
            formData.append("desc", desc);

            const res = await API.post('/admin/members/new',formData, {
                headers: {
                    'Content-Type': 'multipart/form-data; application/json'
                }
            })

            console.log(res.data)

            if (!res.data.success) return false;

            return true;
        }catch(err) {
            return false;
        }
    }

    // remove member
    async delMember(uuid: string | undefined ): Promise<boolean> {
        try {
            const res = await API.post('/admin/members/del',{
                uuid
            })

            if (!res.data.success) return false;

            return true;
        }catch(err){
            return false;
        }
    }
}

const Members = new MembersReq()

export default Members;