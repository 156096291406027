import { API } from "./api";
import { IComments, IPosts } from "./types";

class PostClass {
    // get all posts
    async getAllPosts(type: string) : Promise<IPosts[] | null> {
        try  {
            const res = await API.get(`/posts/all/${type}`)
            
            if (!res.data.success) return null;

            const posts: IPosts[] = res.data.data;
            return posts;
        }
        catch(err) {
            return null;
        }
    }

    // get last by type
    async getLastPost(type: string) : Promise<IPosts | null> {
        try  {
            const res = await API.get(`/posts/last/${type}`)
            
            if (!res.data.success) return null;

            const post: IPosts = res.data.data;
            return post;
        }
        catch(err) {
            return null;
        }
    }

    // get specific post 
    async getPost(uid: string): Promise<IPosts | null> {
        try {
            const res = await API.get(`/posts/${uid}`)
            
            if (!res.data.data) return null;

            const post: IPosts = res.data.data;
            return post;
        }
        catch(err) {
            return null;
        }
    }

    // new post
    async podcastEvent(mode: string, title: string, content: string, desc: string, flag: string, audio: Blob | string= "", pic: Blob | string = "", uid: string = "") : Promise<boolean | Error> {
        try {
            const formData = new FormData();
            formData.append("audio", audio);
            formData.append("picture", pic);
            formData.append("mode", mode);
            formData.append("title", title);
            formData.append("desc", desc);
            formData.append("content", content);
            formData.append("flag", flag);
            formData.append("post_uid", uid);
            formData.append("post_type", "podcasts");

            const res = await API.post('/admin/post',formData, {
                headers: {
                    'Content-Type': 'multipart/form-data; application/json'
                }
            })

            if (!res.data.success) throw new Error(res.data.message);

            return true;
        }
        catch(err) {
            return err;
        }
    }

    async articleEvent(mode: string, title: string, content: string, author: string, desc: string, flag: string, pic: Blob | string = "", uid: string = "") : Promise<boolean | Error> {
        try {
            const formData = new FormData();
            formData.append("picture", pic);
            formData.append("mode", mode);
            formData.append("title", title);
            formData.append("desc", desc);
            formData.append("content", content);
            formData.append("author", author);
            formData.append("flag", flag);
            formData.append("post_uid", uid);
            formData.append("post_type", "articles");

            const res = await API.post('/admin/post',formData, {
                headers: {
                    'Content-Type': 'multipart/form-data; application/json'
                }
            })

            console.log(res)
            if (!res.data.success) throw new Error(res.data.message);

            return true;
        }
        catch(err) {
            console.log(err)
            return err;
        }
    }

    // del post
    async delPost(uid: string = "") : Promise<boolean> {
        try {
            const res = await API.post('/admin/delpost',{
                uid
            })
            
            if(!res.data.success) return false;

            return true;
        }catch(err) {
            return false;
        }
    }

    // -- COMMENTS --

    // post comment
    async postComment(content: string, author: string, uid: string ) : Promise<boolean> {
        try {
            const res = await API.post('/posts/comment',{
                content,
                author,
                uid
            })

            if (!res.data.success) return false;

            return true;
        }
        catch(err) {
            return false;
        }
    }

    // get comments
    async getAllComments(uid: string) : Promise<IComments[] | null> {
        try {
            const res = await API.get(`/posts/${uid}/comments`)

            if (!res.data.success) return null;
            return res.data.data;
        }catch(err) {
            // err
            return null;
        }
    }

    // del comment
    async delComment(uid: string) {
        try {
            const res = await API.post(`/admin/delcomment`,{
                uid
            })

            if (!res.data.success) return null;
            return true;
        }catch(err) {
            // err
            return null;
        }
    }
}

const Post = new PostClass()
export default Post