import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Post from '../../../config/post.request'
import { IPosts } from '../../../config/types'
import ReactMarkdown from 'react-markdown'
import { FaEdit, FaEye } from 'react-icons/fa'
import remarkGfm from 'remark-gfm'
import { BACK_URL } from '../../../config/backendURL'


export default function ManagePodcast({ mode }: { mode :string }) {

    const [ postData, setPostData ] = useState<IPosts>();
    const [ loading, setLoading ] = useState(true);

    const history = useHistory()

    useEffect(() => {
       const initial = async () => {
            if (mode === "edit") {
                const uid = new URLSearchParams(history.location.search).get('uid')
                if (!uid) return history.push('/admin');

                const post: IPosts | null = await Post.getPost(uid);
                if (!post) return history.push('/admin');

                setPostData(post);
            }else if (mode === "add") {}
            else {
                return history.push('/admin')
            }
            return setLoading(false);
       }
       initial();
    }, [ history, mode ])


    return (
        <div className="manage-podcast-container">
            {
                loading ? <p>Chargement....</p>
                :
                <PodcastField mode={mode} previousValue={postData} />
            }
        </div>
    )
}

function PodcastField({ mode, previousValue }: { mode :string, previousValue?: IPosts }) {

    // history
    const history = useHistory()

    // fields
    const [ title, setTitle ] = useState(previousValue?.title || '');
    const [ content, setContent ] = useState(previousValue?.content || '');
    const [ audio, setAudio ] = useState<Blob>();
    const [ audioName, setAudioName ] = useState('');
    const [ pic, setPic ] = useState<Blob>();
    const [ picUrl, setPicUrl ] = useState(previousValue?.uuid ? `${BACK_URL}/files/${previousValue?.uuid}/${previousValue?.picture_path}` : "");
    const [ desc, setDesc ] = useState(previousValue?.desc || '');
    const [ flag, setFlag ] = useState(previousValue?.flag || '');
    const [ messageError, setMessageError ] = useState('');

    // editor
    const [ preview, setPreview ] = useState(false)
    
    // handle input file change
    function handleFiles(type: string, files: FileList | null) {
        if (!files) return;
        if (type === "audio") {
            const audioTune = files[0]
            setAudio(audioTune)
            setAudioName(audioTune.name)
        }else if (type === "image") {
            const image = files[0]

            setPic(image)
            setPicUrl(URL.createObjectURL(image))
        }else return;
    }

    // add podcast
    async function addPodcast() {
        setMessageError('');
        if (!(flag.length > 0 && title.length > 0 && content.length > 0)) {
            if (mode === "add") {
                if (!(typeof audio !== "undefined" && audio?.size > 0) && (typeof pic !== "undefined" && pic?.size > 0)) {
                    return setMessageError("Vous devez remplir tous les champs.")
                }
            }else{
                return setMessageError("Vous devez remplir tous les champs.")
            }
        }

        const insertion = await Post.podcastEvent(mode, title, content, desc, flag, audio, pic, previousValue?.uuid);

        if (insertion === true) {
            return history.push('/admin/?tab=podcasts')
        }else {
            return setMessageError(insertion.toString());
        }
    }

    // del podcast
    async function delPodcast() {
        try {
            const res = await Post.delPost(previousValue?.uuid);

            if (res === true) {
                return history.push('/admin/?tab=podcast')
            }else {
                return setMessageError("Une erreur s'est produite lors de la suppression du podcast");
            }
        }catch(err) {
            return false;
        }
    }

    return (
        <div className="post-field">
            <h2>{ mode === "add" ? "NOUVEAU" : "EDITION"} PODCAST</h2>
            <input value={title} type="text" name="title" id="title-input" onChange={(e) => setTitle(e.target.value)} placeholder="TITRE..." /><br />
            <span className="border-bottom"></span>
            <div className="box-input-container">
                {
                    mode === "add" && (
                        <div className="element-input-container">
                            <input type="file" name="file" id="audio-file" className="inputfile" onChange={(e) => handleFiles("audio",e.target.files)}  />
                            <label htmlFor="audio-file" className="shadow-box">{ audioName.length > 0 ? audioName : "Choisissez un fichier audio"}</label>
                        </div>
                    )
                }
                
                <div className="element-input-container">
                    <input type="file" name="file" id="picture-file" className="inputfile" onChange={(e) => handleFiles("image", e.target.files)}  />
                    {/* @ts-ignore */}
                    <label htmlFor="picture-file" className="shadow-box">{ pic?.name ? pic.name : "Choisissez une image de couverture"} {picUrl.length > 0 && <img src={picUrl} alt={pic?.name}/>} </label>
                </div>
            </div>
            <textarea value={desc} className="description shadow-box" name="desc" id="desc" placeholder="Description" onChange={e => setDesc(e.target.value)}></textarea>
            {/* CONTENT EDITOR */}
            <div className="editor-container shadow-box">
                <div className="top">
                    <button className={preview ? "" : "selected"} onClick={() => setPreview(false)} >
                        <FaEdit /> Contenu
                    </button>
                    <button className={preview ? "selected" : ""} onClick={() => setPreview(true)} >
                        <FaEye /> Previsualiser
                    </button>
                </div>
                <div className="content">
                     {
                        preview ? 

                        <div className="preview-container">
                            <ReactMarkdown plugins={[remarkGfm]} children={content} />
                        </div>
                        :
                        <textarea name="content" id="content-text" value={content} onChange={(e) => setContent(e.target.value)}></textarea>
                     }
                </div>
            </div>
            {/* END CONTENT EDITOR */}
            <p className="mini-subtitle">Thèmes (séparés par des virgules):</p>
            <input value={flag} type="text" className="shadow-box" name="flag" id="flag" placeholder="Thèmes" onChange={e => setFlag(e.target.value)} /><br/>
            {
                messageError.length  > 0 && <p className="info-message error-true">{messageError}</p>
            }
            <button type="button" className="button-primary shadow-box" onClick={addPodcast}>
                { mode === "add" ? "AJOUTER" : "MODIFIER" }
            </button>
            {
                mode === "edit" && 
                <button type="button" className="button-primary shadow-box" onClick={delPodcast}>
                    SUPPRIMER
                </button>
            }
        </div>
    )
}