import React from 'react'

// rendering a subtitle
export default function Subtitle({ subtitle } : { subtitle: string; }) {
    return (
        <div className="subtitle">
            <h2>{subtitle}</h2>
        </div>
    )
}
