import React from 'react'

export default function Page404() {
    return (
        <div id="page-404">
            <img src="/logo.png" alt="logo.png"/>
            <p>Oups ! Cette page n'a pas été trouvée...</p>
        </div>
    )
}
