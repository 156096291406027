import React, { useEffect, useState } from 'react'
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom"
import { IUser } from '../../config/types'
import User from '../../config/user.request'
import Admin from '../../screens/admin'

// Screens
import Articles from '../../screens/articles'
import ArticleDetails from '../../screens/articles/details'
import Login from '../../screens/auth'
import Communaute from '../../screens/communaute'
import Contacts from '../../screens/contacts'
import Home from '../../screens/home'
import Podcasts from '../../screens/podcasts'
import PodcastDetails from '../../screens/podcasts/details'
import Footer from '../footer'
import Page404 from '../others/404'
import Topbar from '../topbar'
import { PrivateRoute } from './protectedroutes'

// navigation
export default function App() {

    const [ isLogged, setIsLogged ] = useState(false);
    const [ loading, setLoading ] = useState(true)
    const [ user, setUser ] = useState<IUser | {}>({})

    useEffect(() => {
        const getUser = async() => {
            const user = await User.getUser()
            
            if (user) {
                setUser(user);
                setIsLogged(true);
            }
            return setLoading(false)
        }
        getUser()
    },[ isLogged ])


    return (
        <Router>
            {
                loading ? 
                    <div className="loading-page-container">
                        <img src="/logo.png" alt="logo.png"/>
                        <p>Chargement...</p>
                    </div>
                :
                <>
                <Topbar /> 
                    <Switch>
                        <Route path="/" component={Home} exact />
                        <Route path="/podcasts" component={Podcasts} exact />
                        <Route path="/podcasts/:id" component={() => <PodcastDetails isLogged={isLogged}  />} />
                        <Route path="/articles" component={Articles} exact />
                        <Route path="/articles/:id" component={() => <ArticleDetails isLogged={isLogged} /> } />
                        <Route path="/commu" component={Communaute} exact />
                        <Route path="/contacts" component={Contacts} exact />
                        <Route path="/login" component={() => <Login setIsLogged={setIsLogged} />} exact />

                        <PrivateRoute path="/admin" isLogged={isLogged} component={Admin} user={user} exact={true} />

                        <Route component={Page404} />
                    </Switch>
                <Footer />
                </>
            }
        </Router>
    )
}
