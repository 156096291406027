import React from 'react'
import Loader from 'react-loader-spinner'

export default function MyLoader() {
    return (
        <div className="loading-container">
            <Loader
                type="ThreeDots"
                color="#C13440"
                height={100}
                width={100}
            />
        </div>
    )
}
