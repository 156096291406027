import React from "react"
import { Redirect } from "react-router-dom";

export const PrivateRoute = ({ path, component, isLogged, ...props}: any) => {
    const Component = component

    return isLogged ? (
        <Component {...props} />
    ) : (
        <Redirect to={{ pathname: '/login' }} />
    );
}