import React, { FormEvent, useEffect, useState } from 'react'
import MyLoader from '../../components/others/loader';
import Subtitle from '../../components/others/subtitle'
import Mail from '../../config/mail.request';

export default function Contacts() {

    useEffect(() => { document.title = "New Wave | CONTACTS" }, [])

    const [ loading, setLoading ] = useState(false);
    const [ info, setInfo ] = useState('');

    // form fields
    const [ name, setName ] = useState('');
    const [ content, setContent ] = useState('');

    async function onSubmit(e: FormEvent<HTMLFormElement>) {
        e.preventDefault();
        setLoading(true);
        setInfo('');

        if (!(name.length > 0) || !(content.length > 0)) return setInfo('Vous devez remplir tous les champs.')
        try {
            const res = await Mail.sendMail(name, content)
             
            if (!res) return setInfo("Une erreur s'est produite lors de l'envoi de votre message.")
            setName('');
            setContent('');
            setInfo("Votre message a bien été transmis !")
        }catch(err) {
            // error
            setInfo("Une erreur s'est produite lors de l'envoi de votre message.");
        }
        return setLoading(false);
    }
    return (
        <main className="contacts-container">
            <Subtitle subtitle="VOUS POUVEZ NOUS CONTACTER VIA CE FORMULAIRE !" />
            <p className="explanation">Une suggestion, une idée, une question, un avis .... écrivez-nous et nous essayerons de répondre dans les meilleurs délais possibles. L’équipe New Wave sera heureuse de vous répondre et d’utiliser vos suggestions pour enrichir son site et son contenu. N’hésitez pas à laisser un contact dans le contenu de votre message afin que nous puissions vous répondre si cela est nécessaire !</p>
            <div className="contacts-form">
                <form onSubmit={onSubmit}>
                    {
                        loading ? <MyLoader /> :
                        <>
                        <input type="text" name="name" id="name" placeholder="Nom" className="shadow-box" value={name} onChange={e => setName(e.target.value)} />
                        <textarea name="content" id="content" placeholder="Contenu" className="shadow-box" value={content} onChange={e => setContent(e.target.value)}></textarea>
                        {
                            info.length > 0 && <p>{info}</p>
                        }
                        <div className="button-container">
                            <button className="button-primary shadow-box">
                                ENVOYER
                            </button>
                        </div>
                        </>
                    }
                </form>
            </div>
        </main>
    )
}
