import React from 'react'
import { Link } from 'react-router-dom'
import { FaNewspaper, FaPodcast, FaUserGraduate, FaUsers } from "react-icons/fa"
import { IUser } from '../../../config/types'

export default function Sidebar({ user } : { user: IUser }) {
    return (
        <div className="sidebar">
            <div className="sidebar-content">
                <h1>NEW WAVE</h1>
                <hr />
                <nav>
                    <div 
                        className="category-component"
                    >
                        <p className="category-name">Contenu</p>
                        <Link
                            to="/admin?tab=podcasts"
                            className="category-info"
                        >
                            <FaPodcast />
                            <p>Podcasts</p>
                        </Link>
                        <Link
                            to="/admin?tab=articles"
                            className="category-info"
                        >
                            <FaNewspaper />
                            <p>Articles</p>
                        </Link>
                    </div>
                    {
                        user.role_rank === 1 && 
                        <div 
                            className="category-component"
                            >
                                <p className="category-name">Administration</p>
                                <Link
                                    to="/admin?tab=users"
                                    className="category-info"
                                >
                                    <FaUsers />
                                    <p>Utilisateurs</p>
                                </Link>
                                <Link
                                    to="/admin?tab=members"
                                    className="category-info"
                                >
                                    <FaUserGraduate />
                                    <p>Membres</p>
                                </Link>
                        </div>
                    }
                </nav>
            </div>
        </div>
    )
}
