import React, { FormEvent, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import MyLoader from '../../components/others/loader';
import User from '../../config/user.request';

export default function Login({ setIsLogged }: any ) {

    useEffect(() => { document.title = "New Wave | LOGIN" }, [])

    const [ username, setUsername ] = useState('');
    const [ password, setPassword ] = useState('');
    const [ loading, setLoading ] = useState(false);
    const [ error, setError ] = useState('')

    const history = useHistory()

    useEffect(() => {
        const isLogged = async () => {
            const res = await User.getUser()
            if (res) {
                return history.push('/admin');
            }
        }
        isLogged()
    },[ history ])

    // submit form
    async function submitForm(e: FormEvent<HTMLFormElement>) {
        e.preventDefault()

        // check if field are not empty
        if (!(username.length > 0) || !(password.length > 0)) return setError("Vous devez renseigner tous les champs");
        
        setLoading(true);
        try {
            const res = await User.login(username, password)

            if (res) {
                // set is logged in global app
                setIsLogged(true);
                setLoading(false);
                return history.push('/admin')
            }else{
                setLoading(false);
                return setError("Identifiant ou mot de passe incorrect");
            }

        }catch(err) {
            setLoading(false);
            return setError("Une erreur s'est produite, veuillez réessayer !")
        }
    }

    return (
        <main className="login-container">
            <form onSubmit={submitForm} >
                {
                    loading ? <MyLoader />:
                    <>
                    <h2>Connexion administrateur</h2>
                    <input className="shadow-box" placeholder="nom d'utilisateur" type="text" name="username" id="username" onChange={(e) => setUsername(e.target.value)}/>
                    <input className="shadow-box" placeholder="mot de passe" type="password" name="password" id="password" onChange={(e) => setPassword(e.target.value)}/>
                    <div className="button-container">
                        <button type="submit" className="button-primary shadow-box">
                            Connexion
                        </button>
                    </div>
                    </>
                }
            </form>
            {
                error.length > 0 && <p>{error}</p>
            }
        </main>
    )
}
