import { useEffect, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import { useHistory, useParams } from 'react-router-dom'
import remarkGfm from 'remark-gfm'
import Comments from '../../../components/others/comments'
import MyLoader from '../../../components/others/loader'
import Subtitle from '../../../components/others/subtitle'
import { BACK_URL } from '../../../config/backendURL'
import Post from '../../../config/post.request'
import { IPosts } from '../../../config/types'

interface IParams {
    id: string;
}

export default function PodcastDetails({ isLogged }: { isLogged: boolean }) {

    const history = useHistory()
    const params: IParams = useParams()

    const [ data, setData ] = useState<IPosts>();
    const [ loading, setLoading ] = useState(true)

    const [ audioUrl, setAudioUrl ] = useState('');

    // Listen change of location
    useEffect(() => {
        const launchPage = async() => {
            if (params) {
                // get podcast
                const data: IPosts | null = await Post.getPost(params.id);

                // check if data
                if (!data) return history.push('/');

                const url = `${BACK_URL}/files/${data.uuid}/${data.podcast_path}`;

                document.title = "New Wave | " + data.title
                
                setAudioUrl(url);
                setData(data);
                setLoading(false);
            }else{
                return history.push('/podcasts')
            }
        }
        launchPage()
    },[history, params])
    

    return (
        <main className="details-container">
            {
                loading ? <MyLoader /> :
                data && 
                <>
                <div className="img-container">
                    <img src={`${BACK_URL}/files/${data.uuid}/${data.picture_path}`} alt={data.picture_path} />
                </div>
                <h2>{data.title}</h2>
                <div className="button-container">
                    <div className="player-container shadow-box">
                    <audio controls preload="auto" style={{ width: "100%" }} controlsList="nodownload">
                        <source src={audioUrl} type="audio/mpeg" />
                    </audio>
                    </div>
                </div>
                <div className="flag-container">
                    {
                        data.flag?.split(',').map(e => e.trim()).map((e,i) => {
                            return (
                                <div className="flag-box shadow-box" key={i}>
                                    <p>{e.toUpperCase()}</p>
                                </div>
                            )
                        })
                    }
                </div>
                <p className="desc">
                    {data.desc}
                </p>
                <ReactMarkdown plugins={[remarkGfm]} children={data.content} />
                <div className="content-bottom">
                    <p className="content-time">
                        {manageDate(data.date)}
                    </p>
                </div>
                <Subtitle subtitle="COMMENTAIRES" />
                <Comments id={data.uuid} isLogged={isLogged} />
                </>
            }
        </main>
    )
}

function manageDate(date: string) {
    const splitDate = date.split('-')
    return `${splitDate[2]}/${splitDate[1]}/${splitDate[0]}`
}
