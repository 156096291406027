import React, { FormEvent, useEffect, useState } from 'react'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { IRole, IUser } from '../../../config/types';
import User from '../../../config/user.request';
import AdminList from './adminlist';

export default function ManageUser() {

    const [ username, setUsername ] = useState('');
    const [ password, setPassword ] = useState('');
    const [ confirmPassword, setConfirmPassword ] = useState('');
    const [ roleList, setRoleList ] = useState<IRole[]>([])
    const [ roleOpen, setRoleOpen ] = useState(false);
    const [ message, setMessage ] = useState('');
    const [ role, setRole ] = useState(0);
    const [ adminList, setAdminList ] = useState<IUser[]>([])
    const [ error, setError] = useState(false);


    useEffect(() => {
        getRoles();
        getAdminList();
    },[])

    // get role list
    const getRoles = async () => {
        const res: IRole[] | null = await User.getRoleList();
        
        if (!res) return setMessage('Erreur lors de la récupération des roles');
        
        return setRoleList(res);
    }

    // edit role choice
    function editRoleChoice(rank: number) {
        const role: IRole | undefined = roleList.find(e => e.role_rank === rank);
        if (!role) return;

        setRoleOpen(false);
        return setRole(role.role_rank);
    }

    
    // get admin list
    const getAdminList = async () => {
        const data: IUser[] | null = await User.getAdminUsers();
        
        if (!data) return;
        return setAdminList(data);
    }

    // ADD USER
    async function submitForm(e: FormEvent<HTMLFormElement>) {
        e.preventDefault()
        setError(false);
        setMessage('');
        if (!(username.length > 0) || !(role > 0) || !(password.length > 0) || !(confirmPassword.length > 0)) {
            setError(true);
            return setMessage("Vous devez renseigner tous les champs.");
        }

        if (password !== confirmPassword) {
            setError(true);
            return setMessage('Les deux mots de passe renseignés ne correspondent pas.');
        }
        try {
            const res = await User.addAdmin(username, password, role);
            if (res) {
                const name = username;
                setMessage(name + ' ajouté avec succès.');
                setUsername('');
                setPassword('');
                setConfirmPassword('');
                setRole(0);
                return await getAdminList();
            }else{
                setError(true);
                return setMessage('Erreur lors de la création de cet administrateur.')
            }
        }catch(err) {
            console.log(err)
        }
    }

    return (
        <div className="manage-users-container">
            <h2>GESTION DES UTILISATEURS</h2>
            <div className="new-users-container">
                <form onSubmit={submitForm}>
                    <input value={username} className="shadow-box" placeholder="nom d'utilisateur" type="text" name="username" id="username" onChange={(e) => setUsername(e.target.value)} autoComplete="off" />
                    <input value={password} className="shadow-box" placeholder="mot de passe" type="password" name="password" id="password" onChange={(e) => setPassword(e.target.value)} autoComplete="off" />
                    <input value={confirmPassword} className="shadow-box" placeholder="confirmation du mot de passe" type="password" name="c-password" id="c-password" onChange={(e) => setConfirmPassword(e.target.value)} autoComplete="off" />
                    <div className="role-selector">
                        <div onClick={() => setRoleOpen(!roleOpen)} className="selector-button shadow-box">
                            <p>{ role > 0 ? roleList.find(e => e.role_rank === role)?.role_name.toLowerCase() : "Rôle" } {roleOpen ? <FaChevronUp /> :<FaChevronDown />}</p>
                        </div>
                        {
                            roleOpen && <div 
                                className="filter-options"
                            >
                                {
                                    roleList.map((r: IRole,i) => {
                                        return <li key={i} className="shadow-box" onClick={() => editRoleChoice(r.role_rank)}>{r.role_name.toLowerCase()}</li>
                                    })
                                }
                            </div>
                        }
                    </div>
                    { message.length > 0 && <p className={`info-message ${'error-'+error}`}>{message}</p>}
                    <div className="button-container">
                        <button type="submit" className="button-primary shadow-box">
                            AJOUTER
                        </button>
                    </div>
                </form>
            </div>
            <AdminList adminList={adminList} getAdminList={getAdminList} roleList={roleList} />
        </div>
    )
}
