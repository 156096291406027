import React, { useEffect, useState } from 'react'
import MemberList from '../../components/commu/membre'
import Subtitle from '../../components/others/subtitle'
import Members from '../../config/members.request'
import { IMember } from '../../config/types';

// Commu details
export default function Communaute() {

    const [ membersList, setMembersList ] = useState<IMember[]>([]);

    useEffect(() => {
        document.title = "New Wave | COMMUNAUTE"
        const getMembers = async () => {
            const req : IMember[] | null = await Members.getAll();

            if (!req) return;

            return setMembersList(req)
        }
        getMembers()
    }, [])

    return (
        <main className="commu-container">
            <Subtitle subtitle="NOS RÉSEAUX" />
            <a href="https://www.instagram.com/newwaveactu/" target="_blank" rel="noreferrer" className="main-info-container shadow-box">
                <img src="/components/logo_commu.png" alt="logo new wave"/>
                <hr/>
                <img id="insta-logo" src="/components/insta_commu.png" alt="logo insta"/>
                <h1>@newwaveactu</h1>
            </a>
            {
                membersList.length > 0 && (
                    <>
                        <Subtitle subtitle="NOS MEMBRES" />
                        <section className="members-container">
                            {
                                membersList.map((m: IMember,i) => {
                                    return <MemberList key={i} member={m} i={i} />
                                })
                            }
                        </section>
                    </>
                )
            }
        </main>
    )
}
