import { API } from "./api"
import { IRole, IUser } from "./types"

class UserClass {
    // login user
    async login(username : string , password: string): Promise<IUser | null> {
        try {
            const res = await API.post('/auth/login', {
                username,
                password
            })

            if (!res.data || !res.data.success) return null;

            const userData: IUser = res.data.data
            return userData
        }catch(err) {
            return null;
        }
    }

    // get user
    async getUser() : Promise<IUser | null>{
        try {
            const res = await API.get('/auth/getuser')
            
            if (!res.data || !res.data.success) return null;

            const userData: IUser = res.data.data
            return userData
        }catch(err) {
            return null;
        }
    }

    // get roles list
    async getRoleList() : Promise<IRole[] | null> {
        try {
            const res = await API.get('/admin/roles');

            if (!res.data || !res.data.success) return null;

            const roles : IRole[] = res.data.data;
            return roles;
        }
        catch(err) {
            return null;
        }
    }

    // add admin user
    async addAdmin(username: string, password: string, role: number): Promise<boolean> {
        try {
            const res = await API.post('/admin/newadmin', {
                username,
                password,
                role
            })

            if (!res.data || !res.data.success) return false;

            return true;
        }  
        catch(err) {
            console.log(err)
            return false;
        }
    }

    // get admin users
    async getAdminUsers() : Promise<IUser[] | null> {
        try {
            const res = await API.get('/admin/adminlist')

            if (!res.data || !res.data.success) return null;

            const adminList: IUser[] = res.data.data;
            return adminList;
        }catch(err) {
            return null;
        }
    }

    // edit role
    async editUserRole(id: string, role: number ): Promise<boolean> {
        try {
            const res = await API.post('/admin/editUser',{
                id,
                role
            })

            if (!res.data || !res.data.success) return false;
            return true;
        }
        catch(err) {
            return false;
        }
    }
}

const User = new UserClass()
export default User