import React, { FormEvent, useEffect, useState } from 'react'
import { FaTrash } from 'react-icons/fa';
import Post from '../../config/post.request'
import { IComments } from '../../config/types'
import MyLoader from './loader';

export default function Comments({ id, isLogged }: { id :string, isLogged: boolean } ) {

    // loading
    const [ loading, setLoading ] = useState(false);

    // comments data
    const [ commentsData, setCommentsData ] = useState<IComments[]>([])

    // form fields
    const [ author, setAuthor ] = useState('');
    const [ content, setContent ] = useState('');

    // set error
    const [ messageError, setMessageError ] = useState('');

    useEffect(() => {
        getComments()
    },[])

    // get comments
    const getComments = async() => {
        const posts: IComments[] | null = await Post.getAllComments(id)
        
        if (!posts) return;
        setCommentsData(posts);
        setLoading(false);
    }

    // post comment
    async function postComment(e: FormEvent<HTMLFormElement> ) {
        e.preventDefault()
        setMessageError('');
        if (!(author.length > 0) || !(content.length > 0)) return setMessageError("Vous devez remplir tous les champs !");

        setLoading(true);
        try {
            const post = await Post.postComment(content, author, id)

            if (!post) return setMessageError("Oups ! Une erreur s'est produite..")

            setContent('');
            setAuthor('');
            return await getComments()
        }catch(err) {
            setLoading(false);
            return setMessageError("Oups ! Une erreur s'est produite..")
        }
    }

    return (
        <div className="details-comment">
            <div className="contacts-form">
                {
                    loading ? <MyLoader /> :
                    <form onSubmit={postComment}>
                        <input value={author} type="text" name="name" id="name" placeholder="Nom" className="shadow-box" onChange={e => setAuthor(e.target.value)} />
                        <textarea value={content} name="content" id="content" placeholder="Contenu" className="shadow-box" onChange={e => setContent(e.target.value)} ></textarea>
                        {
                            messageError.length > 0 && <p>{messageError}</p>
                        }
                        <div className="button-container">
                            <button className="button-primary shadow-box">
                                ENVOYER
                            </button>
                        </div>
                    </form>
                }
            </div>
           {
               commentsData.map(c => {
                   return <CommentElement getComments={getComments} isLogged={isLogged} key={c.uid} data={c} /> 
               })
           }
        </div>
    )
}


// return 1 comment
function CommentElement({ data, isLogged, getComments }: { data : IComments, isLogged: boolean, getComments: () => void }) {

    const time = manageDate(new Date(Number(data.time)).toISOString().split('T')[0])

    // del comment
    async function delComment() {
        try {
            const confirm = window.confirm("Êtes-vous sûr de vouloir supprimer ce commentaire ?");
            if (!confirm) return;
            const del = await Post.delComment(data.uid);
            
            if (del) {
                return await getComments();
            }
        }
        catch(err) {
            // error
            console.log(err)
        }
    }

    return (
        <div className={`comment-container ${isLogged ? 'admin' : 'noadmin'}`}>
            { isLogged && (
                <div className="del-comment" onClick={delComment}>
                    <FaTrash />
                </div>
            )}
            <p className="author">{data.author}</p>
            <p className="content">{data.content}</p>
            <p className="date">{time}</p>
        </div>
    )
}

// edit date
function manageDate(date: string) {
    const splitDate = date.split('-')
    return `${splitDate[2]}/${splitDate[1]}/${splitDate[0]}`
}