import React, { useState } from 'react'
import { FaChevronDown, FaChevronUp, } from 'react-icons/fa';
import { IRole, IUser } from '../../../../config/types'
import User from '../../../../config/user.request';

export default function AdminList({ roleList, adminList, getAdminList } : { roleList : IRole[], adminList: IUser[], getAdminList: () => Promise<void> }) {

    return (
        <div className="admin-list-container">
            {
                adminList.sort((a,b) => a.role_rank - b.role_rank).map((u,i,arr) => {
                    let subtitle = false;
                    if (arr[i-1]?.role_rank !== u.role_rank) {
                        subtitle = true;
                    }

                    const props = {
                        subtitle,
                        roleList,
                        u,
                        getAdminList
                    }

                    return (
                        <UserElement key={i} {...props} />
                    )
                })
            }
        </div>
    )
}

interface IAdminProps {
    subtitle: boolean;
    roleList: IRole[];
    u: IUser;
    getAdminList: () => Promise<void>;
}

function UserElement({ subtitle, roleList, u, getAdminList }: IAdminProps) {

    const [ roleOpen, setRoleOpen ] = useState(false);
    const currentRole = u.role_rank

    // edit role
    async function editRoleChoice(rank: number) {
        try {
            
            const res = await User.editUserRole(u.uuid, rank);
            if (!res) return;
            await getAdminList()
            return setRoleOpen(false);
        }catch(err) {
            return ;
        }

    }


    function setRoleOpenState() {
        if (u.role_rank !== 1) return setRoleOpen(!roleOpen);
        return;
    }

    const roleName = roleList.find(e => e.role_rank === u.role_rank)?.role_name.toLowerCase();
    return (
        <React.Fragment >
            { subtitle && <h2>{roleName}: </h2>}
            <div className="users-container shadow-box">
                <p className="username">{u.username}</p>
                <div className="role-selector">
                    <div onClick={setRoleOpenState} className={`selector-button ${u.role_rank === 1 && "isnoteditable"}`}>
                        <p>{ roleList.find(e => e.role_rank === currentRole)?.role_name.toLowerCase()} {u.role_rank !== 1 && (roleOpen ? <FaChevronUp /> :<FaChevronDown />)}</p>
                    </div>
                    {
                        roleOpen && <div 
                            className="filter-options"
                        >
                            {
                                roleList.filter(e => e.role_rank !== u.role_rank).map((r: IRole,i) => {
                                    return <li key={i} className="shadow-box" onClick={() => editRoleChoice(r.role_rank)}>{r.role_name.toLowerCase()}</li>
                                })
                            }
                        </div>
                    }
                </div>
            </div>
        </React.Fragment>
    )
}