import { API } from "./api"

class MailClass {
    // send mail
    async sendMail(name: string, content : string): Promise<boolean> {
        try {
            const res = await API.post('/mail',{
                name,
                content
            })

            return res.data.success;
        }
        catch(err) {
            return false;
        }
    }
}

const Mail = new MailClass()
export default Mail